/*
 * @Author       : Banmao
 * @Date         : 2022-12-21 17:49:25
 * @LastEditTime : 2023-01-28 13:33:11
 * @LastEditors  : maculosa
 * @FilePath     : /P002/src/views/settings/menus/service.js
 * @Description  :
 */
import request from '@/utils/request';

export function fetchLogList(data) {
  return request({
    url: '/user_behavior/data',
    method: 'POST',
    data
  });
}
export function exportLogList(data) {
  return request({
    url: '/user_behavior/export',
    method: 'POST',
    responseType: 'blob',
    data
  });
}
