/*
 * @Author       : Banmao
 * @Date         : 2022-09-22 15:05:19
 * @LastEditTime : 2023-01-28 13:04:18
 * @LastEditors  : maculosa
 * @FilePath     : /P002/src/views/settings/menus/column.js
 * @Description  :
 */
export default [
  {
    title: '序号',
    key: 'index',
    scopedSlots: {
      customRender: 'index'
    },
    width: 64,
    fixed: true
  },
  {
    title: '登录名称',
    key: 'uname'
  },
  {
    title: '登录IP地址',
    key: 'ip'
  },
  {
    title: '操作模块',
    key: 'module'
  },
  {
    title: '操作行为',
    key: 'operate',
    align: 'center'
  },
  {
    title: '时间',
    key: 'create_time'
  }
];
