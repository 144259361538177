<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template #searchbar>
      <el-form :inline="true" size="mini">
        <el-form-item label="登录名称">
          <el-input
            v-model="searchParams.uname"
            filterable
            clearable
            placeholder="请输入登录名称"
            style="width: 160px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :loading="tableLoading"
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
      stripe
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { fetchLogList, exportLogList } from './service';
import DefaultTable from '@/components/DefaultTable/new';

import columns from './column';
import mixins from '@/mixins';

export default {
  name: 'WarningDaeOffline',
  components: {
    DefaultTable,
    PageHeader
  },
  data() {
    return {
      searchParams: {
        uname: ''
      },
      columns,
      data: [],
      tableLoading: true,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || this.pagination.pageSize || 10,
        ...this.searchParams
      };
      fetchLogList(params).then(res => {
        this.data = res.data;
        this.tableLoading = false;
        if (res.code == 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count * 1
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.handleQuery({
        current: this.pagination.current,
        pageSize: val
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      exportLogList(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
